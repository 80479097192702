<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_venue.approved_venue_reservation') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="venue_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_tpm.vanue')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.venue_id"
                            :options="venueList"
                            id="venue_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_venue.approved_venue_reservation') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <!-- <router-link to="venue-reservation-form" :class="'btn btn-success text-light'">{{  $t('globalTrans.add_new') }}</router-link> -->
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                <template v-slot:cell(index)="data">
                                  {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(start_date)="data">
                                  <span class="capitalize">{{ data.item.start_date | dateFormat }}</span>
                                </template>
                                <template v-slot:cell(total_amount)="data">
                                  {{ $n(data.item.total_amount) }}
                                </template>
                                <template v-slot:cell(end_date)="data">
                                  <span class="capitalize">{{ data.item.end_date | dateFormat }}</span>
                                </template>
                                <template v-slot:cell(reservation_info)="data">
                                  {{$t('globalTrans.name')}} : {{ $i18n.locale === 'bn' ? data.item.name_bn : data.item.name }}
                                  <br/>
                                  {{$t('globalTrans.email')}} : {{ data.item.email }}
                                  <br/>
                                  {{$t('globalTrans.mobile')}} : {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                  <br/>
                                  {{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? data.item.reservation_address_bn : data.item.reservation_address }}
                                  <br/>
                                  {{$t('elearning_config.organization')}} : {{ $i18n.locale === 'bn' ? data.item.reservation_org_bn : data.item.reservation_org }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 @click="edit(data.item)">
                                    <i class="fas fa-eye"></i>
                                  </a>
                                  <a href="javascript:" title="Bill Calculation" class="btn_table_action table_action_status" v-b-modal.modal-4  v-if="data.item.bill_generate_status == 1" @click="edit(data.item)">
                                    <i class="fas fa-money-bill"></i>
                                    <!-- {{$t('elearning_venue.bill_generate')}} -->
                                  </a>
                                </template>
                              </b-table>
                              <b-pagination
                                  align="center"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total-rows="pagination.totalRows"
                                  @input="searchData"
                                  />
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('elearning_venue.venue_reservation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('elearning_venue.venue_reservation') + ' ' + $t('globalTrans.details') }}
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="$t('elearning_venue.bill_calculation')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { venueReservationList, venueReservationToggleStatus, venueReservationFinalSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Form from './Form'
import Details from '../venue-reservation/Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form,
      Details
    },
    data () {
        return {
          search: {
            org_id: 0,
            venue_id: 0,
            status: 4,
            office_type_id: 0,
            office_id: 0,
            fiscal_year_id: 0
          },
          trainingCategoryList: [],
          venueList: []
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        if (this.$store.state.Auth.authUser.org_id === 12) {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        } else {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('elearning_config.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
              { label: this.$t('elearning_config.organization'), class: 'text-center' },
              { label: this.$t('elearning_tpm.vanue'), class: 'text-center' },
              { label: this.$t('elearning_venue.reservation_info'), class: 'text-center' },
              { label: this.$t('globalTrans.start_date'), class: 'text-center' },
              { label: this.$t('globalTrans.end_date'), class: 'text-center' },
              { label: this.$t('elearning_venue.rent_amount'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'venue_bn' },
              { key: 'reservation_info' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_amount' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'venue' },
              { key: 'reservation_info' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_amount' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        // office_type_id: this.$store.state.dataFilters.officeTypeId,
        // office_id: this.$store.state.dataFilters.officeId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.org_id': function (newValue) {
        this.venueList = this.geVenueList(newValue)
      }
    },
    methods: {
      finalSave (item) {
        this.toggleStatusCustom2(trainingElearningServiceBaseUrl, venueReservationFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.pendingConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      geVenueList (orgId) {
        let venueList = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
        if (orgId) {
          venueList = venueList.filter(item => item.org_id === orgId)
          if (this.$store.state.dataFilters.officeId) {
            venueList = venueList.filter(item => parseInt(item.office_id) === parseInt(this.$store.state.dataFilters.officeId))
          }
        }
        return venueList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, venueReservationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, venueReservationList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const venueObj = this.$store.state.TrainingElearning.commonObj.venus.find(doc => doc.value === parseInt(item.venue_id))
          const venueData = {}
          if (typeof venueObj !== 'undefined') {
            venueData.venue = venueObj.text_en
            venueData.venue_bn = venueObj.text_bn
          } else {
            venueData.venue = ''
            venueData.venue_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const reservationOrgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.reservation_org_id))
          const reservationOrgData = {}
          if (typeof reservationOrgObj !== 'undefined') {
            reservationOrgData.reservation_org = reservationOrgObj.text_en
            reservationOrgData.reservation_org_bn = reservationOrgObj.text_bn
          } else {
            reservationOrgData.reservation_org = ''
            reservationOrgData.reservation_org_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, venueData, OfficeTypeData, reservationOrgData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
